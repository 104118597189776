<template>
    <div class="content">
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line">
        <div class="nav">
            <span style="color:rgba(51, 51, 51, .6)">首页</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <!-- <span style="color:rgba(51, 51, 51, .6)">要闻动态</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span> -->
            <span>详情</span>
        </div>
        <div class="context">
            <div class="title">{{infoData.articleTitle}}</div>
            <div class="text">
                <span>作者:{{infoData.artcleAuthor}}</span>
                <span style="margin:0 30px;">时间：{{infoData.createdTime}}</span>
                <span>来源：{{infoData.articleFrom}}</span>
            </div>
            <div class="info" v-html="infoData.articleText">
        


            </div>
        </div>
        <!-- <img src="../../assets/img/index/flower-left.png" alt="" class="flower-left">
        <img src="../../assets/img/index/flower-right.png" alt="" class="flower-right"> -->
    </div>
</template>
  
<script>
import {getArticleInfo} from '@/api'
  export default {
    name: "Trends",
    components: {

    },
    data() {
      return {
        infoData:{}
        
      };
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo(){
            getArticleInfo({
                articleId:this.$route.query.id
            }).then(res=>{
                if(res.code==200){
                    console.log(res)
                    this.infoData=res.data
                }
            })
        }
    }
  };
</script>
<style scoped lang="scss">
.flower-left{
    width: 443px;
    height: 285px;
    position:absolute;
    left: -250px;
    bottom: -250px;
    z-index: 99;
}
.flower-right{
    width: 393px;
    height: 393px;
    position:absolute;
    right: -250px;
    z-index: 99;
    bottom: -200px;

}
.content{
    width: 1400px;
    margin: 0 auto;
    position: relative;

    .nav{
        width: 100%;
        height: 85px;
        font-size: 16px;
        font-family: SimSun;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 20px;
    }
    .top-line{
        width: 100%;
        height: 85px;
        margin-top: 15px;
    }
    .context{
        .title{
            font-size: 24px;
            font-family: FZCuSong-B09;
            font-weight: 400;
            color: #333333;
            text-align: center;
        }
        .text{
            font-size: 18px;
            font-family: SimSun;
            font-weight: 400;
            color: #999999;
            text-align: center;
            margin-top: 29px;
        }
        .info{
            font-size: 20px;
            font-family: SimSun;
            font-weight: 400;
            color: #333333;
            margin-top: 38px;
        }
    }
    
}

</style>
  
  